import ScenicSubmitButton from '../../components/inputs/ScenicSubmitButton.react';
import './UploadTypeDialogContent.css';

type Props = {
  on3DModelSelected: () => any;
  onCustomAvatarSelected: () => any;
  onOpenToggle: () => any;
};

export default function UploadTypeDialogContent({
  on3DModelSelected,
  onCustomAvatarSelected,
  onOpenToggle,
}: Props) {
  return (
    <div className='UploadTypeDialogContent'>
      <h2 className='UploadTypeDialogContent__heading'>
        What kind of content are you wanting to upload?
      </h2>
      <p className='UploadTypeDialogContent__subheading'>
        The 3D Uploader can be used to upload general 3D Models
        (in GLB/GLTF format), and also custom avatar models
        (VRMs, GLB/GLTF) that you can use to represent
      </p>
      <br />
      <ScenicSubmitButton
        onClick={() => {
          on3DModelSelected();
          onOpenToggle();
        }}
        variant="outlined"
        sx={{
          width: '100%',
        }}
        type="submit"
      >
        3D Model
      </ScenicSubmitButton>
      <br />
      <ScenicSubmitButton
        onClick={() => {
          onCustomAvatarSelected();
          onOpenToggle();
        }}
        variant="outlined"
        sx={{
          width: '100%',
        }}
        type="submit"
      >
        Custom Avatar
      </ScenicSubmitButton>
    </div>
  );
}