import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import './ThreeDModelScreen.scss';
import ModelGridItem, {
  Model,
} from '../../components/ModelGridItem/ModelGridItem.react';
import Border from '../../components/Border.react';
import UploadDialog from './UploadDialog.react';
import UploadTypeDialog from './UploadTypeDialog.react';
import AvatarGridItem, {
  Avatar,
} from '../../components/AvatarGridItem/AvatarGridItem.react';

const RequestOptions = {
  headers: { Authorization: localStorage.getItem('token') ?? '' },
};
export default function ThreeDModelScreen() {
  const [models, setModels] = useState<Model[]>([]);
  const [avatars, setAvatars] = useState<Avatar[]>([]);

  const [loading, setLoading] = useState(true);
  const [gotError, setGotError] = useState(false);

  const [isAvatarsTabSelected, setIsAvatarsTabSelected] =
    useState<boolean>(false);

  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openUploadTypeDialog, setOpenUploadTypeDialog] = useState(false);
  const [selectedAvatarUploadOption, setSelectedAvatarUploadOption] =
    useState<boolean>(false);

  console.log(`process.env`, process.env);

  const getData = useCallback(async () => {
    setModels([]);
    setLoading(true);
    setGotError(false);

    let endpoint = '';
    if (isAvatarsTabSelected) {
      endpoint = 'avatars';
    } else {
      endpoint = 'threeDModels';
    }

    await axios
      .get(`${process.env.REACT_APP_BASE_URL}api/${endpoint}`, RequestOptions)
      .then((response) => {
        if (isAvatarsTabSelected) {
          var responseAvatars = response.data.data.avatars;
          var filteredAvatars = responseAvatars.filter((avt: any) => {
            return avt['headshot'] != null;
          });

          setAvatars(filteredAvatars);
        } else {
          setModels(response.data.models);
        }
      })
      .catch((error) => {
        setGotError(true);
        setModels([]);
      });

    setLoading(false);
  }, [isAvatarsTabSelected]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDelete = useCallback(
    (id: string, index: number) => {
      // start loading
      setLoading(true);

      if (window.confirm('Are you sure??')) {
        let endpoint = '';
        if (isAvatarsTabSelected) {
          endpoint = `avatars/deleteAvatar/${id}`;
        } else {
          endpoint = `threeDModels/${id}`;
        }

        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}api/${endpoint}`,
            RequestOptions,
          )
          .then((w) => w.data)
          .then((w) => {
            if (w.success) {
              if (isAvatarsTabSelected) {
                avatars.splice(index, 1);
                setAvatars([...avatars]);
              } else {
                models.splice(index, 1);
                setModels([...models]);
              }
            } else {
              window.alert('Something went wrong');
            }
          })
          .catch((error) => {
            console.log(error);
            window.alert('Avatar not found');
          });
      }

      // stop loading
      setLoading(false);
    },
    [models, avatars, isAvatarsTabSelected],
  );

  return (
    <div id="content">
      <UploadTypeDialog
        open={openUploadTypeDialog}
        onOpenToggle={() => setOpenUploadTypeDialog(!openUploadTypeDialog)}
        on3DModelSelected={() => {
          setSelectedAvatarUploadOption(false);
          setOpenUploadTypeDialog(false);
          setOpenUploadDialog(true);
        }}
        onCustomAvatarSelected={() => {
          setSelectedAvatarUploadOption(true);
          setOpenUploadTypeDialog(false);
          setOpenUploadDialog(true);
        }}
      />
      <UploadDialog
        open={openUploadDialog}
        onOpenToggle={() => setOpenUploadDialog(!openUploadDialog)}
        onAvatarUploaded={() => {
          setIsAvatarsTabSelected(true);
          getData();
        }}
        onModelCreated={(newModel: Model) => setModels([...models, newModel])}
        isAvatarOptionSelected={selectedAvatarUploadOption}
      />
      <div className="header">
        <h1>MY 3D CONTENT</h1>
        <div>
          <button
            onClick={() => {
              setOpenUploadTypeDialog(true);
            }}
          >
            Upload Content
          </button>
        </div>
      </div>
      <div className="subHeader">
        <div>
          <button
            className={
              'tabButton ' + (!isAvatarsTabSelected ? 'tabButtonSelected' : '')
            }
            onClick={() => {
              setIsAvatarsTabSelected(false);
              getData();
            }}
          >
            3D Models
          </button>
          <button
            className={
              'tabButton ' + (isAvatarsTabSelected ? 'tabButtonSelected' : '')
            }
            onClick={() => {
              setIsAvatarsTabSelected(true);
              getData();
            }}
          >
            Avatars
          </button>
        </div>
        <p>
          3D Content can be accessed for AR Scenes on the Scenic mobile app.
        </p>
      </div>

      <Border />

      {
        // if got error
        gotError ? (
          <div className="nocontent">
            <div className="border">
              {/* <img src="/no_3d_models_icon.png" alt="" /> */}
              <p>An error occured while fetching models.</p>
            </div>
          </div>
        ) : (
          // if no error
          <>
            {
              // if loading, show loader
              loading ? (
                <Grid
                  className="modelsGrid"
                  container
                  spacing={1}
                  sx={{ backgroundColor: 'black' }}
                >
                  <div className="loading">
                    <CircularProgress color="success" />
                  </div>
                </Grid>
              ) : (
                // if not loading, show data
                <>
                  {
                    // if no data, show no data message
                    models.length === 0 && avatars.length === 0 ? (
                      <div className="nocontent">
                        <div className="border">
                          <img src="/no_3d_models_icon.png" alt="" />
                          <p>
                            you do not currently have any 3d content uploaded
                          </p>
                        </div>
                      </div>
                    ) : (
                      // if data available, show data
                      <Grid
                        className="modelsGrid"
                        container
                        spacing={1}
                        sx={{ backgroundColor: 'black' }}
                      >
                        {
                          // check type of data
                          isAvatarsTabSelected ? (
                            // if avatars were retrieved
                            <>
                              {avatars.map((avatar, index) => (
                                <AvatarGridItem
                                  key={avatar._id}
                                  index={index}
                                  avatar={avatar}
                                  onDelete={handleDelete}
                                />
                              ))}
                            </>
                          ) : (
                            // if models were retrieved
                            <>
                              {models.map((model, index) => (
                                <ModelGridItem
                                  key={model._id}
                                  index={index}
                                  model={model}
                                  onDelete={handleDelete}
                                />
                              ))}
                            </>
                          )
                        }
                      </Grid>
                    )
                  }
                </>
              )
            }
          </>
        )
      }
    </div>
  );
}
