import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Model } from '../../components/ModelGridItem/ModelGridItem.react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import LoadingOverlay from '../../components/Loading/LoadingOverlay';
import { Box, Button, Typography } from '@mui/material';
import { WarningModal } from './WarningModal.react';
import ScenicSubmitButton from '../../components/inputs/ScenicSubmitButton.react';

export type FormModel = {
  name: string;
  description: string;
  tags: string[];
  privacy: 'private' | 'public' | '';
};

export default function ThreeDModelDialogContent({
  onAvatarUploaded,
  onModelCreated,
  onOpenToggle,
  onSelectVRMCalled,
  isAvatarOptionSelected,
}: {
  onAvatarUploaded: () => any;
  onModelCreated: (newModel: Model) => any;
  onOpenToggle: () => any;
  onSelectVRMCalled: (id: String) => any;
  isAvatarOptionSelected: boolean;
}) {
  // for avatar .vrm file input
  // const inputVRMFile = useRef(null);
  const inputVRMFile = useRef<HTMLInputElement | null>(null);
  const [isUploadingVRM, setIsUploadingVRM] = useState(false);

  // store the avatarId returned by "/upload"
  const [avatarId, setAvatarId] = useState<String | null>(null);

  const onSelectVRMButtonClick = () => {
    // `current` points to the mounted file input element
    if (inputVRMFile.current != null) {
      inputVRMFile.current.click();
    }
  };

  const [uploading, setUploading] = useState(false);
  const [showLargeFileWarning, setShowLargeFileWarning] = useState(false);
  const {
    isLoaded,
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    unload,
  } = useUnityContext({
    loaderUrl: '/build.loader.js',
    dataUrl: '/build.data',
    frameworkUrl: '/build.framework.js',
    codeUrl: '/build.wasm',
    webglContextAttributes: { preserveDrawingBuffer: true },
  });

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    sendMessage(
      '[Bridge]',
      'getAuthToken',
      localStorage.getItem('token') ?? '',
    );
    if (isAvatarOptionSelected) {
      sendMessage('[Bridge]', 'isAvatarPanel', 'true');
    } else {
      sendMessage('[Bridge]', 'isAvatarPanel', 'false');
    }
  }, [isLoaded, sendMessage, isAvatarOptionSelected]);

  const handleLargeFile = useCallback(() => {
    setShowLargeFileWarning(true);
  }, []);

  /**
   * Handles the submission when Unity sends a "SetModal" event,
   * when "Upload Content" is pressed
   */
  const handleSubmissionSuccess = useCallback(
    async (stringData) => {
      // prevent multiple uploading
      if (uploading === true || isUploadingVRM === true) return;

      setUploading(true);
      setIsUploadingVRM(true);

      if (isAvatarOptionSelected) {
        const { title, hasAnimation, headshotFile } = JSON.parse(stringData);

        const headshotBase64Response = await fetch(
          'data:text/plain;base64,' + headshotFile,
        );
        const headshotBlob = await headshotBase64Response.blob();

        const formData = new FormData();
        formData.append('name', title);
        formData.append('hasAnimation', hasAnimation);
        formData.append('headshot', headshotBlob, 'headshot.png');

        try {
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/avatars/uploadAvatar/${avatarId}`,
            formData,
            {
              headers: { Authorization: localStorage.getItem('token') ?? '' },
            },
          );
        } catch (error) {
          console.log(error);
        }

        onAvatarUploaded();
      } else {
        const {
          title,
          description,
          privacy,
          hasAnimation,
          modelFile,
          thumbnailFile,
        } = JSON.parse(stringData);

        const thumbnailBase64Response = await fetch(
          'data:text/plain;base64,' + thumbnailFile,
        );
        const thumbnailBlob = await thumbnailBase64Response.blob();
        const modelsBase64Response = await fetch(
          'data:text/plain;base64,' + modelFile,
        );
        const modelBlob = await modelsBase64Response.blob();

        const formData = new FormData();
        formData.append('name', title);
        formData.append('hasAnimation', hasAnimation);
        formData.append('thumbnail', thumbnailBlob, 'thumbnail.png');
        formData.append('models', modelBlob);
        formData.append('description', description);
        formData.append('privacy', privacy);

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/threeDModels`,
          formData,
          {
            headers: { Authorization: localStorage.getItem('token') ?? '' },
          },
        );

        onModelCreated(response.data.model);
      }

      setUploading(false);
      setIsUploadingVRM(false);

      await unload();
      onOpenToggle();
    },
    [
      onModelCreated,
      onOpenToggle,
      unload,
      isAvatarOptionSelected,
      onAvatarUploaded,
      isUploadingVRM,
      avatarId,
      uploading,
    ],
  );

  /**
   * Called when user presses "Upload Content",
   * Sends the message to Unity WebGL to get headshot file
   */
  const onUploadAvatar = useCallback(async () => {
    sendMessage('[Bridge]', 'UploadContent');
  }, [sendMessage]);

  /**
   * Called when user selects a VRM file from file explorer,
   * calls /upload to convert VRM to GLB,
   * and sent to Unity Webgl
   */
  const onVRMModelSelected = useCallback(async () => {
    if (inputVRMFile.current != null) {
      if (inputVRMFile.current.files != null) {
        setIsUploadingVRM(true);

        const formData = new FormData();
        formData.append('vrmfile', inputVRMFile.current.files[0]);

        sendMessage('[Bridge]', 'vrmFileSelected');

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/avatars/upload`,
            formData,
            {
              headers: { Authorization: localStorage.getItem('token') ?? '' },
            },
          );

          if (response.data['success']) {
            // stores avatar id, because needed later for /uploadAvatar
            setAvatarId(response.data['data']['avatarId']);
            // also send the avatar ID to [UploadDialog], so that it can call /cancel
            onSelectVRMCalled(response.data['data']['avatarId']);
            // send message to unity that URL for glb has been recieved
            sendMessage(
              '[Bridge]',
              'vrmFileConverted',
              response.data['data']['glbFileUrl'],
            );
          }
        } catch (error) {}

        setIsUploadingVRM(false);
      }
    }
  }, [sendMessage, onSelectVRMCalled]);

  useEffect(() => {
    // @ts-ignore
    addEventListener('SetModel', handleSubmissionSuccess);
    addEventListener('SelectedLargeFile', handleLargeFile);
    return () => {
      // @ts-ignore
      removeEventListener('SetModel', handleSubmissionSuccess);
      removeEventListener('SelectedLargeFile', handleLargeFile);
    };
  }, [
    addEventListener,
    removeEventListener,
    handleSubmissionSuccess,
    handleLargeFile,
  ]);

  useEffect(() => {
    addEventListener('SelectVRM', onSelectVRMButtonClick);
    return () => {
      removeEventListener('SelectVRM', onSelectVRMButtonClick);
    };
  }, [addEventListener, removeEventListener, onSelectVRMButtonClick]);

  return (
    <>
      <WarningModal
        open={showLargeFileWarning}
        onClose={() => setShowLargeFileWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Typography variant="h6">
            The file you selected is too large!
          </Typography>
          <Typography>
            Make sure your upload is <br /> less than 20MB.
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setShowLargeFileWarning(false)}
          >
            OK
          </Button>
        </Box>
      </WarningModal>

      {/* loaders */}
      {uploading && <LoadingOverlay />}
      {isUploadingVRM && <LoadingOverlay />}

      {/* UNITY WEBGL */}
      <Unity
        unityProvider={unityProvider}
        style={{ width: 960, height: 600 }}
      />

      {/* "Select VRM" button for selecting VRM avatar */}

      {/* white spaces */}

      {/* "Upload Content" button for avatar */}

      {/* input field for selecting VRM in case of avatar */}
      <input
        type="file"
        id="file"
        ref={inputVRMFile}
        onChange={onVRMModelSelected}
        style={{ display: 'none' }}
        accept=".vrm"
      />
    </>
  );
}
