import { Button, ButtonProps, styled } from '@mui/material';

const StyledSubmitButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&.MuiButton-root': {
    fontFamily: "'Bebas Neue'",
    fontSize: '1.5rem',
    fontWeight: 500,
    padding: '24px 16px',
  },
  '&.MuiButton-outlined': {
    borderRadius: '24px',
    borderWidth: '2px',
  },
}));
export default function ScenicSubmitButton(props: ButtonProps) {
  return <StyledSubmitButton {...props} />;
}
