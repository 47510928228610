import { Box, Card, CardActions, CardMedia, Grid } from '@mui/material';

export type Avatar = {
  _id: string;
  name?: string;
  headshot?: string;
};

type Props = {
  index: number;
  avatar: Avatar;
  onDelete: (id: string, index: number) => any;
};

export default function AvatarGridItem({
  index,
  avatar: { _id: id, name, headshot },
  onDelete,
}: Props) {
  return (
    <Grid item xs={3}>
      <Card
        sx={{
          boxShadow: '0px 0.05rem 0.05rem 0px rgba(247,237,249,0.85)',
          maxWidth: 245,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CardMedia
            component="img"
            height="150"
            alt="Avatar's Headshot"
            src={headshot ? headshot : '/default_model_image.png'}
          />
        </Box>
        <CardActions sx={{ backgroundColor: 'black', padding: '0 8px' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box
                component="p"
                sx={{
                  color: 'white',
                  maxWidth: '8rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {name}
              </Box>
            </Grid>
            <Grid item>
              <a href="#!" onClick={() => onDelete(id, index)}>
                <Box
                  component="img"
                  sx={{ height: '1rem' }}
                  src="/trash_can_icon.png"
                  alt=""
                />
              </a>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
}