import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ThreeDModelDialogContent from './ThreeDModelDialogContent.react';
import { Model } from '../../components/ModelGridItem/ModelGridItem.react';
import { useCallback, useState } from 'react';
import axios from 'axios';

type Props = {
  onAvatarUploaded: () => any;
  onModelCreated: (newModel: Model) => any;
  onOpenToggle: () => any;
  open: boolean;
  isAvatarOptionSelected: boolean;
};

const ScenicDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  // width: '600px',
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: '8px',
    minWidth: '1020px',
    '& .MuiDialogContent-root': {
      padding: '20px 24px',
    },
  },
}));

const ScenicDialogTitle = styled(DialogTitle)<DialogTitleProps>(
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    padding: '12px 24px',
  }),
);

const IconButtonWithNoHoverEffect = styled(IconButton)<IconButtonProps>(
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
);

export default function UploadDialog({
  onAvatarUploaded,
  onModelCreated,
  onOpenToggle,
  open,
  isAvatarOptionSelected,
}: Props) {
  // store the avatarId returned by "/upload"
  const [avatarId, setAvatarId] = useState<String | null>(null);

  const onSelectVRMCalled = useCallback((id: String) => {
    console.log('GOT AVATAR ID TO CANCEL: ' + id);
    setAvatarId(id);
  }, []);

  // in case of avatar uploading,
  // if user has called /upload,
  // we send /cancel so that the avatar can be deleted,
  // if it /uploadAvatar was not called
  const onClosePressed = useCallback(() => {
    if (isAvatarOptionSelected) {
      if (avatarId != null) {
        axios.delete(
          `${process.env.REACT_APP_BASE_URL}api/avatars/cancel/${avatarId}`,
          {
            headers: { Authorization: localStorage.getItem('token') ?? '' },
          },
        );
      }
    }

    onOpenToggle();
  }, [isAvatarOptionSelected, onOpenToggle, avatarId]);

  return (
    <ScenicDialog open={open}>
      <ScenicDialogTitle>
        Upload New 3D Content
        <IconButtonWithNoHoverEffect
          onClick={onClosePressed}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <img src="/popup_x_icon.png" alt="Close Dialog" height="32" />
        </IconButtonWithNoHoverEffect>
      </ScenicDialogTitle>
      <DialogContent>
        <ThreeDModelDialogContent
          onAvatarUploaded={onAvatarUploaded}
          onOpenToggle={onOpenToggle}
          onModelCreated={onModelCreated}
          onSelectVRMCalled={onSelectVRMCalled}
          isAvatarOptionSelected={isAvatarOptionSelected}
        />
      </DialogContent>
    </ScenicDialog>
  );
}
